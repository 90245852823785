
export default {
    name: "Tabs",
    model: {
        event: "change",
        prop: "value",
    },
    props: {
        value: {
            type: [String, Number],
            default: 0,
        },
        noSelectionIndicator: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        forceUpdate() {
            this.$refs.tabs?.onResize()
        },
    },
}
