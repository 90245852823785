export function storyblokRouteToSchueler() {
    return { path: "/schueler" }
}

export function storyblokRouteToFirmen() {
    return { path: "/firmen" }
}

export function storyblokRouteToSchulen() {
    return { path: "/schulen" }
}

export function storyblokRouteToArticle(article) {
    return {
        path: `/artikel/${article.slug}`,
    }
}

export const TAB_MAIN_ROUTES = {
    schueler: {
        name: "Für Schüler",
        ...storyblokRouteToSchueler(),
    },
    firmen: {
        name: "Für Unternehmen",
        ...storyblokRouteToFirmen(),
    },
    schulen: {
        name: "Für Lehrkräfte",
        ...storyblokRouteToSchulen(),
    },
}
