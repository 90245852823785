
import Tabs from "@/components/base/design/Tabs"
import OPicture from "@/components/base/image/OPicture"
import { getRegion } from "@/services/v2/region.service"
import { TAB_MAIN_ROUTES } from "@/helpers/routingStoryblok"
import customdomain from "@/mixins/customdomain"

export default {
    name: "RegionLandingPageBlok",
    components: {
        Tabs,
        OPicture,
    },
    mixins: [customdomain],
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    provide() {
        return {
            region: () => this.region,
        }
    },
    async fetch() {
        this.region = await this.$api.run(getRegion, this.blok.regionSlug)
    },
    data: () => ({
        region: {},
    }),
    computed: {
        currentTab() {
            let path = this.$route.path
            return this.tabRoutes.findIndex((tab) => tab.path === path)
        },
        tabRoutes() {
            if (!this.blok.tabs) return Object.values(TAB_MAIN_ROUTES)
            return this.blok.tabs.map((tab) => TAB_MAIN_ROUTES[tab])
        },
    },
    async created() {
        this.region = await this.$api.run(getRegion, this.blok.regionSlug)
        if (this.blok.hideFooter) {
            this.$store.dispatch("meta/addMeta", { footer: { hidden: true } })
        }
    },
    async mounted() {
        setTimeout(() => {
            this.$refs.tabs?.forceUpdate()
        }, 500)
    },
    head() {
        if (this.blok.customCSS) {
            return {
                style: this.blok.customCSS,
            }
        }
    },
}
